import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import BlogCard from 'components/blog-card'

function PricingBlog({ posts }) {
  return (
    <Section>
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-10-desktop is-12-tablet">
            <h2 className="title has-text-centered">
              More on{' '}
              <span className="has-text-success">
                about app development costs
              </span>
            </h2>
            <br />
            <br />
            <Columns className="is-multiline is-centered">
              {posts.edges.map(({ node: article }) => (
                <Columns.Column
                  className="is-4-desktop is-5-tablet"
                  key={article.slug}
                >
                  <BlogCard article={article} />
                </Columns.Column>
              ))}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
PricingBlog.propTypes = {
  posts: PropTypes.object,
}
export default PricingBlog

import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function Budget() {
  return (
    <Section className="mt-0">
      <Container>
        <Columns className="is-mobile">
          <Columns.Column className="p-5 is-offset-1-desktop is-4-desktop is-3-tablet is-hidden-mobile content">
            <StaticImage
              src="https://www.datocms-assets.com/20955/1661108694-mobile-maturity-checklist-cover.png?fm=jpg&w=500"
              alt="A UK App Developer having a positive impact on business results"
              placeholder="blurred"
            />
            <p className="has-text-centered is-size-6 is-grey p-5">
              The Mobile Maturity Checklist can help you understand what to
              budget for.
              <br />
              <Link
                className="mt-3 button is-sm is-leafy"
                to="/resources/mobile-maturity-checklist"
              >
                View Details
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-6-desktop is-6-tablet is-12-mobile content ">
            <h2 className="title">
              How is an{' '}
              <span className="has-text-success">app budget determined?</span>
            </h2>
            <p className="">
              Most companies want to set an{' '}
              <Link to="/mobile-technology-radar/strategies/agile-budgets">
                agile budget
              </Link>{' '}
              to cover the next 6-24 months. We help you make sure your budget
              is enough to get you where you need to be. This is often based on
              necessary team-shape and <Link to="/services">services</Link>.
            </p>
            <h3>Example</h3>
            <p>
              A typical 12-month £400,000 app engagement might look like this:
            </p>
            <Columns>
              <Columns.Column className="is-6">
                <h5>Months 1-7</h5>
                <ul className="ml-0">
                  <li>Strategy sprint</li>
                  <li>Design sprint</li>
                  <li>Release 1 Development</li>
                  <li>Quality Assurance</li>
                  <li>Alpha &amp; Beta Releases</li>
                  <li>Live Release No. 1</li>
                </ul>
              </Columns.Column>
              <Columns.Column className="is-6">
                <h5>Months 8-12</h5>
                <ul className="ml-0">
                  <li>Helpdesk Support</li>
                  <li>Data Analysis</li>
                  <li>User Research</li>
                  <li>Design Sprint</li>
                  <li>Release 2 Development</li>
                  <li>Quality Assurance</li>
                  <li>Live Release No. 2</li>
                </ul>
              </Columns.Column>
            </Columns>
            <h5>Example Team Shape</h5>
            <p>
              Digital Advisor, User Researcher, Product Designer, Mobile
              Developer x2, Cloud Developer, Tester, Product Owner.{' '}
            </p>
            <p>
              <br />
              <Link
                to="/contact"
                className="button is-primary is-leafy is-medium is-primary"
              >
                Let's talk about your app
              </Link>
            </p>
          </Columns.Column>
        </Columns>
        <Separator rtl="true" className="column is-hidden-mobile is-offset-1" />
      </Container>
    </Section>
  )
}

export default Budget

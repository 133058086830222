import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Hero, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function HeroComponent() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="container content">
          <Columns className="is-tablet is-vcentered">
            <Columns.Column className="is-5 is-offset-1">
              <h1 className="title is-size-1 is-size-3-mobile">
                How much does it cost to{' '}
                <span className="has-text-success">develop an app?</span>
              </h1>
              <p className="is-size-4">
                Good question. Typically, our clients invest between £250K and
                £1m a year in developing or growing their mobile apps.
              </p>
              <p />
            </Columns.Column>
            <Columns.Column className="is-5">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120498-culture.png?fm=jpg&compress=auto&w=500"
                alt="Culture"
                placeholder="blurred"
              />
            </Columns.Column>
          </Columns>
          <Separator className="is-hidden-mobile mt-6" />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default HeroComponent

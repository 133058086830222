/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta/Meta'

// Pricing Route
import PricingPage from 'routes/pricing'

export default PricingPage

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.about.seoMetaTags.tags} />
}

export const query = graphql`
  query PricingQuery {
    about: datoCmsPage(slug: { eq: "pricing" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
    }
    posts: allDatoCmsArticle(
      limit: 3
      filter: {
        slug: {
          in: [
            "app-development-cost-uk"
            "how-long-does-it-really-take-to-develop-an-app"
            "agile-projects-blank-cheque-no-guarantees"
          ]
        }
      }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          excerpt
          isFeatured
          featuredMedia {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "1280"
                h: "660"
                maxW: 800
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 3.0
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function Examples() {
  return (
    <Section className="mt-0">
      <Container>
        <Columns className="is-mobile">
          <Columns.Column className="is-offset-1 is-5-desktop is-5-tablet is-12-mobile content ">
            <h2 className="title">
              Some <span className="has-text-success">examples</span>
            </h2>
            <p className="">
              Here's some examples of how different businesses invest in apps.
              Note that these are realistic but have been adapted to protect the
              identity of our clients.
            </p>
            <h4>Example 1</h4>
            <p>
              An established £50m turnover businesses invests £800K a year to
              grow three digital products that increase employee retention and
              customer lifetime value. They rely on us to scale the tech with
              their growth and improve mobile CX.
            </p>
            <h4>Example 2</h4>
            <p>
              An early stage VC-backed London startup invests £500K a year to
              develop their app and find product market fit. They rely on us to
              manage quality, release often and learn from data.
            </p>
            <h4>Example 3</h4>
            <p>
              A global instrument manufacturer invests £300K a year with us to
              build its next generationg of smart devices. They rely on us to
              conduct user research and feed that into product design and
              development across desktop and mobile.
            </p>

            {/* Your app needs to benefit from your hard-earned industry know-how. With our collaborative, results-driven approach, you will develop an app that plays to your strengths and gives real business impact. */}

            <p>
              <br />
              <Link
                to="/contact"
                className="button is-primary is-leafy is-medium is-primary"
              >
                Let's talk about your app
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-offset-1-desktop is-4-desktop is-4-tablet is-hidden-mobile content">
            <StaticImage
              src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&w=500"
              alt="A UK App Developer having a positive impact on business results"
              placeholder="blurred"
            />
          </Columns.Column>
        </Columns>
        <Separator className="column is-hidden-mobile is-offset-1" />
      </Container>
    </Section>
  )
}

export default Examples

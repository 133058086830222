import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function Quote() {
  return (
    <Section className="">
      <Container className="content">
        <form
          name="request-quote"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Columns>
            <Columns.Column className="is-offset-1 is-4">
              <h2 className="title">&nbsp;</h2>

              <div className="field mt-6">
                <label className="label">Your name*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="text"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Your email*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Your telephone</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="text"
                    name="telephone"
                  />
                </div>
              </div>
            </Columns.Column>
            <Columns.Column className="is-offset-1 is-5">
              <h2 className="title">
                <span className="has-text-success">What about </span>your app?
              </h2>
              <p>
                Complete this form and we'll get in touch to help you understand
                what investment your app might need.
              </p>
              <div className="field mt-6">
                <label className="label">How might we be able to help?</label>
                <div className="control">
                  <textarea
                    className="textarea is-success"
                    name="message"
                    required
                  />
                </div>
              </div>

              <p className="has-text-right mt-6">
                <button
                  className="button is-primary is-leafy is-medium"
                  type="submit"
                >
                  Request a quote
                </button>
              </p>
            </Columns.Column>
          </Columns>
        </form>
        <Separator className="is-hidden-mobile mt-6" rtl />
      </Container>
    </Section>
  )
}

export default Quote

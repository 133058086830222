import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Component
import HeroComponent from './components/hero'
import Budget from './components/budget'
import Examples from './components/examples'
import Quote from './components/quote'
import PricingBlog from './components/pricing-blog'

function Pricingpage({ data: { posts } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <HeroComponent />
      <Budget />
      <Examples />
      <Quote />
      <PricingBlog posts={posts} />
    </Layout>
  )
}

Pricingpage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Pricingpage
